<template>
    <div class="w-100 h-100">
        <div class="d-flex w-100 h-100 justify-content-center align-items-center">
            <div>
                <b-img center :src="applicationImage" alt="applicationImage"></b-img>
                <h3 class="mt-6">{{ $t('HOME.WELCOME_TEXT') }}</h3>
            </div>
        </div>
        <st-modal
            hide-footer
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            customClass="pt-15"
            customHeaderClass="px-12 pt-14"
            customBodyClass="px-12"
            id="user-profile-modal"
            v-if="!profileIsCompleted"
        >
            <template #header>
                <div class="header-wrapper d-flex justify-content-start">
                    <div class="brand-logo mr-4">
                         <b-img :src="logoImage"/>
                    </div>
                    <div class="text-section">
                        <p class="text-uppercase font-weight-bold pb-0 mb-0">
                            {{ $t('SET_PROFILE_DATA.WELCOME_MODAL.BRAND.NAME') }}
                        </p>
                        <small class="text-uppercase">
                           {{ $t('SET_PROFILE_DATA.WELCOME_MODAL.BRAND.ORGANISATION') }}
                        </small>
                    </div>
                </div>
            </template>
            <template #body>
                <Loader  v-if="isLoading" :logo="logoImage"></Loader>
                <welcome-modal v-if="selectedModal === 'welcome'" @selectModal="selectModal"/>
                <individual-modal v-if="selectedModal === 'citizen'" @selectModal="selectModal"/>
                <legal-form v-if="selectedModal === 'legal_entity'" @selectModal="selectModal" :user="currentUser"/>
            </template>
            <template #footer>
                <p>footer</p>
            </template>
        </st-modal>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import WelcomeModal from '@/modules/home/components/welcome-modal';
import IndividualModal from '@/modules/home/components/individual-modal';
import LegalForm from '@/modules/home/components/legal-form';

import { ProfilePermissions } from "@/modules/profile/profile-permissions";

export default {
    name: 'app-home-page',
    components: {
        WelcomeModal,
        IndividualModal,
        LegalForm
    },
    data() {
        return {
            selectedModal: '',
            applicationImage: `${process.env.BASE_URL}media/st-application-registred.png`,
            isError: false,
        };
    },
    computed: {
        ...mapState({
            loading: state => state.shared.loading,
        }),
        ...mapGetters({
            currentUser: 'auth/currentUser',
            registrationUserType: 'auth/registrationUserType',
        }),
        logoImage() {
            return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ-stema.png`;
        },
        isLoading () {
            return this.loading['profile/getLocality'] || this.loading['profile/getCounties'] || this.loading['profile/getVillages'] || this.isError;
        },
        profileIsCompleted () {
            if (!this.hasPermissionToCompleteProfile) return true;
            return this.currentUser.profile_completed;
        },
        hasPermissionToCompleteProfile() {
            return new ProfilePermissions(this.currentUser).complete;
        }
    },
    methods: {
        onLogout() {
            this.$store.dispatch('auth/logout').then(() => this.$router.push({ name: 'signin' }));
        },
        selectModal(payload) {
            this.selectedModal = payload;
        },
    },
    created() {
        this.selectedModal = this.registrationUserType || 'welcome';
    }
}
</script>

<style scoped lang="scss" src="@/assets/sass/pages/home/user-profile-modal.scss"></style>
