<template>
    <div>
        <div class="mb-4">
            <p class="st-dark-large">
                {{ $t("SET_PROFILE_DATA.WELCOME_MODAL.HEADER.TOP_P") }}
            </p>
            <p class="font-size-lg mt-4">
                {{ $t("SET_PROFILE_DATA.WELCOME_MODAL.HEADER.BOTTOM_P_LEGAL") }}
            </p>
        </div>
        <div>
            <form class="form" novalidate="novalidate" id="st_legal_form">
                <div>
                    <p class="text-uppercase font-weight-bold font-size-lg my-5">
                        {{ $t("SET_PROFILE_DATA.LEGAL_FORM.COMPANY") }}
                    </p>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div>
                            <div class="form-group">
                                <label>
                                    {{ fields.unique_registration_code.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.unique_registration_code.name]"
                                        :ref="fields.unique_registration_code.name"
                                        :name="fields.unique_registration_code.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.company_name.label }} *
                                </label>
                                <div label="">
                                    <st-input-text
                                        v-model="model[fields.company_name.name]"
                                        :ref="fields.company_name.name"
                                        :name="fields.company_name.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.county_id.label }} *
                                </label>
                                <div label="">
                                    <st-autocomplete
                                        v-model="model[fields.county_id.name]"
                                        :options="fields.county_id.options"
                                        :ref="fields.county_id.name"
                                        :name="fields.county_id.name"
                                        :model="model"
                                        :formValidation="fv"
                                        :fields="fields"
                                    >
                                    </st-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.locality_id.label }} *
                                </label>
                                <div label="">
                                    <st-autocomplete
                                        v-model="model[fields.locality_id.name]"
                                        :options="fields.locality_id.options"
                                        :ref="fields.locality_id.name"
                                        :name="fields.locality_id.name"
                                        :disabled="!fields.locality_id.options.length"
                                        :model="model"
                                        :formValidation="fv"
                                        :fields="fields"
                                    ></st-autocomplete>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{ fields.village_id.label }} *
                                </label>
                                <div label="">
                                    <st-autocomplete
                                        v-model="model[fields.village_id.name]"
                                        :options="fields.village_id.options"
                                        :ref="fields.village_id.name"
                                        :name="fields.village_id.name"
                                        :disabled="!fields.village_id.options.length"
                                        :model="model"
                                        :formValidation="fv"
                                        :fields="fields"
                                    ></st-autocomplete>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div>
                            <div class="form-group">
                                <label>
                                    {{ fields.street.label }} *
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.street.name"
                                        :name="fields.street.name"
                                        v-model="model[fields.street.name]"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                 <div class="col-sm">
                                    <div class="form-group">
                                        <label>
                                            {{ fields.street_number.label }} *
                                        </label>
                                        <st-input-text
                                            :ref="fields.street_number.name"
                                            :name="fields.street_number.name"
                                            v-model="model[fields.street_number.name]"
                                            allow-only-alphanumeric
                                        />
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <label>
                                        {{ fields.staircase.label }}
                                    </label>
                                    <st-input-text
                                        :ref="fields.staircase.name"
                                        :name="fields.staircase.name"
                                        v-model="model[fields.staircase.name]"
                                    />
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-sm">
                                         <label>
                                            {{ fields.block.label }}
                                        </label>
                                        <st-input-text
                                            :ref="fields.block.name"
                                            :name="fields.block.name"
                                            v-model="model[fields.block.name]"
                                        />
                                    </div>
                                    <div class="col-sm">
                                        <label>
                                            {{ fields.floor.label }}
                                        </label>
                                        <st-input-text
                                            :ref="fields.floor.name"
                                            :name="fields.floor.name"
                                            v-model="model[fields.floor.name]"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <div class="col-sm">
                                            <label>
                                            {{ fields.apartment.label }}
                                        </label>
                                        <st-input-text
                                            :ref="fields.apartment.name"
                                            :name="fields.apartment.name"
                                            v-model="model[fields.apartment.name]"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm">
                                    <div class="form-group">
                                        <label>
                                            {{ fields.postal_code.label }} *
                                        </label>
                                        <st-input-text
                                            :ref="fields.postal_code.name"
                                            :name="fields.postal_code.name"
                                            v-model="model[fields.postal_code.name]"
                                            set-validation
                                            :max-length="6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p class="text-uppercase font-weight-bold font-size-lg my-5">
                        {{ $t("SET_PROFILE_DATA.LEGAL_FORM.LEGAL_REPRESENTATIVE")}}
                    </p>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.last_name.label }}*
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.last_name.name]"
                                    :ref="fields.last_name.name"
                                    :name="fields.last_name.name"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.first_name.label }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.first_name.name]"
                                    :ref="fields.first_name.name"
                                    :name="fields.first_name.name"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.personal_numeric_code.label }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.personal_numeric_code.name]"
                                    :ref="fields.personal_numeric_code.name"
                                    :name="fields.personal_numeric_code.name"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.license.label }}
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.license.name]"
                                    :ref="fields.license.name"
                                    :name="fields.license.name"
                                    set-validation
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.email.label }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.email.name]"
                                    :ref="fields.email.name"
                                    :name="fields.email.name"
                                    :disabled="true"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>
                                {{ fields.phone_number.label }} *
                            </label>
                            <div label="">
                                <st-input-text
                                    v-model="model[fields.phone_number.name]"
                                    :ref="fields.phone_number.name"
                                    :name="fields.phone_number.name"
                                    phone-validation
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <id-card-details
                    :model="model"
                    :fields="fields"
                    :formValidation="fv"
                    :idCardFields="idCardFields"
                />
                <div class="pb-lg-0 pb-5">
                    <div class="d-flex justify-content-between">
                        <st-button
                            variant="link"
                            class="font-size-h6 px-5 py-3 my-3 mr-3"
                            :callback="changeProfileType"
                        >
                            {{
                                $t("SET_PROFILE_DATA.FORM_BUTTONS.CHANGE_TYPE")
                            }}
                        </st-button>
                        <st-button
                            variant="primary"
                            :spinner="loading['auth/updateProfile']"
                            customClass="font-size-h6 px-5 py-3 my-3 mr-3"
                            :callback="onSubmit"
                        >
                            {{ $t("SET_PROFILE_DATA.FORM_BUTTONS.SAVE") }}
                        </st-button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ProfileModel } from "@/modules/profile/profile-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ProfileModel;
const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.personal_numeric_code,
    fields.first_name,
    fields.last_name,
    fields.license,
    fields.unique_registration_code,
    fields.company_name,
    fields.county_id,
    fields.locality_id,
    fields.village_id,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.email,
    fields.phone_number,
    fields.id_card_serial,
    fields.id_card_number,
    fields.id_card_expiration_date,
    fields.id_card_issuer,
]);

export default {
    props: {
        user: Object,
    },
    components: {
        IdCardDetails: () => import("./id-card-section"),
    },
    data() {
        return {
            model: {
                sector: '',
                apartment: '',
                block: '',
                staircase: '',
                floor: '',
                user_type_name: "legal_entity"
            },
            fields,
            rules: formSchema.rules(),
            fv: null,
            idCardFields: ['id_card_serial', 'id_card_number', 'id_card_expiration_date', 'id_card_issuer']
        };
    },
    watch: {
        selected(value) {
            //reset form (errors) when the selection between citizen is changed
            if (value) this.fv.resetForm();
        },
    },
    computed: {
        ...mapGetters({
            loading: "shared/loading",
            defaultCountyId: "shared/defaultCountyId",
        }),
    },
    methods: {
        ...mapActions({
            updateProfile: "auth/updateProfile",
        }),
        changeProfileType() {
            this.$emit("selectModal", "welcome");
        },
        onSubmit() {
            this.fv.validate().then((status) => {
                if (status === "Valid") {
                    let payload = {
                        ...this.model,
                        locality: this.fields.locality_id.options.find((el) => el.value === this.model.locality_id)?.text || '',
                        county: this.fields.county_id.options.find((el) => el.value === this.model.county_id)?.text || '',
                        village: this.fields.village_id.options.find((el) => el.value === this.model.village_id)?.text || '',
                    }

                    this.onUpdateProfile(payload);
                }
            });
        },
        onUpdateProfile(formData) {
            // Email is not send in payload because it cannot be updated
            const { email, ...payload } = formData;
            this.updateProfile(payload).then((user) => {
                this.$bvModal.hide("user-profile-modal");

                const fullName = `${user.first_name} ${user.last_name}`;
                this.$alert({
                    title: `${this.$t("SET_PROFILE_DATA.SUCCESS_MODAL.TITLE", [
                        fullName,
                    ])}`,
                    type: "success",
                    text: this.$t("SET_PROFILE_DATA.SUCCESS_MODAL.TEXT"),
                    confirmButtonText: this.$t(
                        "SET_PROFILE_DATA.SUCCESS_MODAL.CONFIRM_BUTTON"
                    ),
                    callback: () => {
                        this.$router.go();
                    },
                });
            });
        },
    },
    created() {
        const initialValues = formSchema.initialValues(this.user);
        this.model = { ...initialValues, ...this.model};
        this.model.county_id = this.defaultCountyId;
        this.idCardFields.forEach((field) => {
            this.fields[field].required = true;
        })
    },
    mounted() {
        this.fv = createFormValidation("st_legal_form", this.rules);
    },
};
</script>
